<template>
  <a-modal
    :visible="visible"
    title="創建兌換所帳號"
    okText="確認創建"
    cancelText="取消"
    :closable="false"
    :maskClosable="false"
  >
    <template #footer>
      <a-button key="back" :disabled="loading" @click="handleCancel">取消</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk">確認新增</a-button>
    </template>

    <a-form
      :model="formState"
      layout="vertical"
      autocomplete="off"
    >
      <a-row>
        <a-col :span="24">
          <a-form-item
            label="合作社代號"
            name="coop_id"
          >
            <a-input v-model:value="formState.coop_id" readonly size="large" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="兌換所代號"
            name="exchange_code"
            :rules="[{ required: true, message: '請輸入兌換所代號' }]"
          >
            <a-input v-model:value="formState.exchange_code" :disabled="loading" size="large" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="兌換所名稱"
            name="first_name"
            :rules="[{ required: true, message: '請輸入兌換所名稱' }]"
          >
            <a-input  v-model:value="formState.first_name" :disabled="loading" suffix="兌換所" size="large" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="電子信箱"
            name="email"
            :rules="[{ required: true, message: '請輸入電子信箱' }]"
          >
            <a-input v-model:value="formState.email" :disabled="loading" size="large" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="密碼"
            name="password"
            :rules="[{ required: true, message: '請輸入密碼' }]"
          >
            <a-input v-model:value="formState.password" :disabled="loading" size="large" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import axios from 'axios'
import * as bip39 from 'bip39'
import CryptoJS from 'crypto-js'
import { defineComponent, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'

export default defineComponent({
  props: ['visible'],
  components: {},
  setup() {
    const store = useStore();

    const initialState = {
      coop_id: store.state.auth.info.coop_info.coop_id,
      exchange_code: '',
      email: '',
      password: '',
      last_name: '兌換所',
      first_name: ''
    }

    const formState = reactive({ ...initialState });
    
    function resetForm() {
      Object.assign(formState, initialState);
    }

    const registerAuth = ref({
      token: '',
      info: {}
    });

    const loading = ref(false);

    return {
      formState,
      resetForm,
      registerAuth,
      loading
    }
  },
  computed: {
    isPhone() {
      if (!this.formState.email.includes('@')) {
        return true
      }
      return false
    }
  },
  methods: {
    async handleOk() {
      await this.register();
    },
    handleCancel() {
      this.$emit('cancel');
      this.resetForm();
    },

    /* private func */
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validatePhone(phone) {
      if (phone.length==10 && !phone.includes('@')) {
        return true
      }
      return false
    },
    _checkRequired() {
      let err = '';

      if (this.formState.exchange_code == '') {
        err = '請輸入兌換所代號';
      } else if (this.formState.email == '') {
        err = '請輸入電子信箱';
      } else if (this.isPhone && !this.validatePhone(this.formState.email)) {
        err = '手機號碼格式有誤';
      } else if (!this.isPhone && !this.validateEmail(this.formState.email)) {
        err = '電子信箱格式有誤';
      } else if (this.formState.password == '') {
        err = '請輸入密碼';
      } else if (this.formState.first_name == '') {
        err = '請輸入兌換所名稱';
      }

      if (err) message.error(err)
      return err
    },

    /* wallet functions */
    generateMnemonic() {
      const mnemonic = bip39.generateMnemonic(256);
      return mnemonic
    },
    async createWallet(email, mnemonic, uid) {
      await this.$store.dispatch('common/wallet/createWalletWithMnemonic', {
        name: email,
        mnemonic: mnemonic,
        password: uid,
        prefix: this.$store.state.common.env.addrPrefix,
      });
    },

    /* register function */
    async register() {
      let err = this._checkRequired();
      if (err) return;

      this.loading = true;

      try {
        let resultForm = {
          role: "3",
          exchange_code: '',
          username: '',
          email: '',
          password: ''
        }

        // 處理 email, phone
        resultForm.exchange_code = this.formState.exchange_code;
        resultForm.username = this.formState.email;
        resultForm.email = this.formState.email;
        resultForm.password = this.formState.password;
        if (this.isPhone) {
          resultForm.phone = this.formState.email;
          resultForm.email = `${resultForm.phone}@tcoop.org`;
        }

        // 加上 coop_info
        resultForm.coop_info = this.$store.state.auth.info.coop_info.id;

        // register auth
        const registerUrl = process.env.VUE_APP_API + '/users';
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.auth.token}`
          }
        }
        let registerResponse = await axios.post(registerUrl, resultForm, config);
        let registerRes = registerResponse.data;
        console.log(registerRes);

        // create ex_user_infos
        const exUserInfoUrl = process.env.VUE_APP_API + '/ex-user-infos';
        let euiResponse = await axios.post(exUserInfoUrl, {
          users: registerRes.id,
          coop_id: this.formState.coop_id,
          exchange_code: resultForm.exchange_code,
          exchange_name: this.formState.first_name
        }, config);
        let euiRes = euiResponse.data;
        console.log(euiRes)

        // login exchange account & get jwt
        const loginUrl = process.env.VUE_APP_API + '/auth/local';
        const response = await axios.post(loginUrl, {
          identifier: resultForm.email,
          password: resultForm.password
        });
        const loginRes = response.data;
        this.registerAuth = {
          token: loginRes.jwt,
          info: loginRes.user
        }
        
        // update user's last_name & first_name
        let userInfoId = await this.getUserInfo('user_info');
        await this.updateUserInfo(userInfoId, 'user_name', {
          last_name: this.formState.last_name,
          first_name: this.formState.first_name
        });
        
        // create wallet
        await this.$store.dispatch('common/env/init');
        const mnemonic = this.generateMnemonic();
        await this.createWallet(resultForm.email, mnemonic, loginRes.user.id.toString());
        const walletAddress = this.$store.getters['common/wallet/address'];

        // encrypt mnemonic
        let salt = Math.random().toString(36).slice(-10);
        let encryptedMnemonic = CryptoJS.AES.encrypt(
          mnemonic,
          `${process.env.VUE_APP_ENCRYPT_PREFIX}${salt}`
        ).toString();
        await this.updateUserInfo(userInfoId, 'enc_k', salt);

        // save address & mnemonic
        let userWalletId = await this.getUserInfo('wallet');
        await this.updateWalletInfo(userWalletId, walletAddress, encryptedMnemonic);
        await this.updateUserInfo(userInfoId, 'wallet_address', walletAddress);

        // completed
        message.success('創建兌換所帳號成功');
        this.resetForm();
        this.$emit('confirm');
      } catch (error) {
        console.log(error);

        if (error.response.status == 400) {
          message.error('創建失敗，此帳號已經註冊過了')
        } else {
          message.error('創建發生錯誤，請稍後再試')
        }
      } finally {
        this.loading = false;
      }
    },

    /* api */
    async getUserInfo(type) {
      const url = process.env.VUE_APP_API + '/users/me';
      const config = {
        headers: {
          Authorization: `Bearer ${this.registerAuth.token}`
        }
      }
      let response = await axios.get(url, config);
      let res = response.data;
      console.log(res);

      if (type == 'wallet') {
        return res.wallet
      } else if (type == 'user_info') {
        return res.user_info
      } else {
        return res
      }
    },
    async updateWalletInfo(walletInfoId, walletAddress, mnemonic) {
      let url = process.env.VUE_APP_API + `/wallets/${walletInfoId}`;
      try {
        const params = {
          mnemonic: mnemonic,
          wallet_Address: walletAddress
        }
        const config = {
          headers: {
            Authorization: `Bearer ${this.registerAuth.token}`
          }
        }
        await axios.put(url, params, config);
      } catch (error) {
        console.log(error)
      }
    },
    async updateUserInfo(userInfoId, key, value) {
      let params = {};
      if (key == 'enc_k') {
        params = { enc_k: value }
      } else if (key == 'wallet_address') {
        params = { wallet_Address: value }
      } else if (key == 'user_name') {
        params = value;
      }

      let url = process.env.VUE_APP_API + `/user-infos/${userInfoId}`;
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${this.registerAuth.token}`
          }
        }
        await axios.put(url, params, config);
      } catch (error) {
        console.log(error)
      }
    }
  }
})
</script>