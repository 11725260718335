<template>
<div class="app-exchanges-page tenmiles-page">
  <div class="page__wrapper">
    <div class="page__header">
      <a-row>
        <a-col :span="12">
          <a-typography-title :level="2">兌換所帳戶</a-typography-title>
        </a-col>
        <a-col :span="12" align="right">
          <a-button size="large" @click="openCreateAccountModal">創建兌換所</a-button>
        </a-col>
      </a-row>
    </div>

    <div class="page__body">
      <div class="body-item">
        <a-row :gutter="12">
          <template v-if="!loading">
            <a-col
              :span="8"
              v-for="account in exchangeAccounts"
            >
              <a-card :style="{ marginBottom: '12px' }">
                <a-typography-title :level="4">{{ account.exchange_code }} {{ account.user_info.first_name }}兌換所</a-typography-title>
                <div :style="{ color: '#757575' }">{{ account.email }}</div>
              </a-card>
            </a-col>
          </template>

          <template v-else>
            <a-col :span="24" align="center">
              <a-spin />
            </a-col>
          </template>
        </a-row>
      </div>

      <a-divider></a-divider>

      <div class="body-item">
        <a-typography-title :level="2">操作</a-typography-title>

        <div class="item-actions">
          <a-button class="action-btn" large @click="toTransactionPage">兌換所交易</a-button>
          <a-button class="action-btn" large @click="toAccountingPage">兌換所收支紀錄</a-button>
        </div>
      </div>
    </div>
  </div>

  <CreateAccountModal
    :visible="createAccountModal.visible"
    @confirm="handleCreateAccountModalConfirm"
    @cancel="handleCreateAccountCancel"
  />
</div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'
import CreateAccountModal from './components/CreateAccountModal.vue'

export default defineComponent({
  components: {
    CreateAccountModal,
  },
  setup() {
    const store = useStore();

    /* Data */
    const loading = ref(false);
    const exchangeAccounts = ref([]);

    /* Api */
    const getExchangeAccounts = (async () => {
      loading.value = true;

      const url = process.env.VUE_APP_API + `/users?role=3&coop_info.id=${store.state.auth.info.coop_info.id}`;
      const config = {
        headers: {
          Authorization: `Bearer ${store.state.auth.token}`
        }
      }
      const response = await axios.get(url, config);
      exchangeAccounts.value = response.data;

      loading.value = false;
    });

    /* Mounted */
    onMounted(async() => {
      await getExchangeAccounts();
    });

    return {
      loading,
      exchangeAccounts,

      /* Api */
      getExchangeAccounts
    }
  },
  data() {
    return {
      /* create account modal */
      createAccountModal: {
        visible: false
      }
    }
  },
  methods: {
    /* Router */
    toTransactionPage() {
      this.$router.push({ name: 'AppTransaction' });
    },
    toAccountingPage() {
      this.$router.push({ name: 'AppAccounting' });
    },

    /* create account modal */
    openCreateAccountModal() {
      this.createAccountModal.visible = true;
    },
    handleCreateAccountModalConfirm() {
      this.getExchangeAccounts();
      this.createAccountModal.visible = false;
    },
    handleCreateAccountCancel() {
      this.createAccountModal.visible = false;
    }
  }
})
</script>